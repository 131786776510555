import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Button} from "mdbreact";
import QRCode from "./QRCode";

class QRCodeForm extends React.Component {

    onSuccess = (data) => {
        const {setClientNumber, sendClientNumber} = this.props;
        setClientNumber(data);
        sendClientNumber();
    };

    onError = (error) => {
        //do nothing, because Reader is constantly processing the image from the camera and when not seeing the QR code it throws an error
    };

    render() {
        const {hideQRCode} = this.props;
        return (
            <>
                <QRCode
                    onSuccess={this.onSuccess}
                    onError={this.onError}
                ></QRCode>
                <div className="select-client__box__qrcode">
                    <Button
                        className="btn btn-brand btn-flat"
                        onClick={hideQRCode}
                    >
                        <FormattedMessage id="select_client.qr_code.cancel" />
                    </Button>
                </div>
            </>
        );
    };
};

QRCodeForm.propTypes = {
    setClientNumber: PropTypes.func,
    sendClientNumber: PropTypes.func,
    hideQRCode: PropTypes.func
};

export default QRCodeForm;
