import React from 'react';
import PropTypes from 'prop-types';
import Login from "./Login";
import {connect} from "react-redux";
import {loginUser} from "../../../routines/security";
import {fetchFairBasicInfo} from "../../../routines/fair";

class LoginContainer extends React.Component {

    state = {
        login: '',
        password: ''
    };

    componentDidMount() {
        const {place, date} = this.props;

        if (!place || !date) {
            //TODO uncomment when api will work with non auth method
            // this.props.fetchFairBasicInfo();
        }
    }
    
    handleLogin = () => {
        this.props.loginUser({login: this.state.login, password: this.state.password});
    };

    handleOnChange = (event) => {
        this.setState({...this.state, [event.target.name]: event.target.value})
    };

    render() {
        const {login, password} = this.state;
        const {loading, error, place, date} = this.props;

        return <Login
            place={place}
            date={date}
            login={login}
            password={password}
            error={error}
            loading={loading}
            handleLogin={this.handleLogin}
            handleOnChange={this.handleOnChange}
        />
    }
}

LoginContainer.propTypes = {
  error: PropTypes.object,
  loading: PropTypes.bool,
  loginUser: PropTypes.func,
  place: PropTypes.string,
  date: PropTypes.string
};

const mapStateToProps = state => {
    return {
        loading: state.loginUser.loading,
        error: state.loginUser.error,
        place: state.fairInfo.data.place,
        date: state.fairInfo.data.date,
    }
};

export default connect(mapStateToProps, {fetchFairBasicInfo, loginUser})(LoginContainer);
