import request from './request';

const Security = {
    login: ({login, password}) => {
        return request({
            url: '/oauth/v2/token',
            method: 'GET',
            params: {
                username: login,
                password: password,
                grant_type: 'password',
                client_id: process.env.REACT_APP_CLIENT_ID,
                client_secret: process.env.REACT_APP_CLIENT_SECRET
            }
        });
    },
    refresh: () => {
        return request({
            url: '/oauth/v2/token',
            method: 'GET',
            params: {
                refresh_token: localStorage.refreshToken,
                grant_type: 'refresh_token',
                client_id: process.env.REACT_APP_CLIENT_ID,
                client_secret: process.env.REACT_APP_CLIENT_SECRET
            }
        });
    },
};

export default Security;