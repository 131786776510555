import React from "react";
import { connect } from "react-redux";
import getColumns from "../../Table/getColumns";
import Cart from "./Cart";
import {
    completeCartPromiseCreator,
    fetchActiveCart
} from "../../../routines/cart";
import { bindPromiseCreators } from "redux-saga-routines";
import { bindActionCreators } from "redux";
import FairService from "../../../services/api/Fair";

class CartContainer extends React.Component {
    state = {
        showDialog: false,
        status: "success",
        fairProducts: [],
        fairCartProducts: [],
        loadingState: {},
    };

    componentDidMount() {
        this.props.fetchActiveCart();
        FairService.getProducts({
            mylist: 0,
            s_s_supplier: "all-suppliers",
            for_cart: true,
            username: this.props.username
        }).then(response => this.setState({ fairProducts: response.data }));

        FairService.getFairProductsList({ username: this.props.username }).then(
            response => this.setState({ fairCartProducts: response.data })
        );
    }

    makeOrder = () => {
        this.props
            .completeCartPromiseCreator()
            .then(() => {
                this.setState({
                    showDialog: true,
                    status: "success"
                }, () => {
                    FairService.getFairProductsList({ username: this.props.username }).then(
                        response => this.setState({ fairCartProducts: response.data })
                    );
                });
            })
            .catch(() => {
                this.setState({
                    showDialog: true,
                    status: "error"
                });
            });
    };

    handleCloseNotification = () => {
        this.setState({
            showDialog: false
        });
    };

    addProduct = async (week, productId, amount) => {
        const loadStateKey = `${productId}-${week}`;

        this.setState((prevState) => ({ loadingState: {...prevState.loadingState, [loadStateKey]: true} }))

        await FairService.addProduct(
            week,
            productId,
            amount === "" ? 0 : amount,
            { username: this.props.username }
        );

        await FairService.getProducts({
            mylist: 0,
            s_s_supplier: "all-suppliers",
            for_cart: true,
            username: this.props.username
        }).then(response => this.setState({ fairProducts: response.data }));

        await FairService.getFairProductsList({ username: this.props.username }).then(
            response => this.setState((prevState) => ({ fairCartProducts: response.data, loadingState: {...prevState.loadingState, [loadStateKey]: false} }))
        );

        this.props.fetchActiveCart();
    };

    render() {
        const { data, columns, itemsTotal, isLoading, username } = this.props;
        const {
            showDialog,
            status,
            fairProducts,
            fairCartProducts
        } = this.state;
        return (
            <Cart
                data={data}
                columns={columns}
                itemsTotal={itemsTotal}
                isLoading={isLoading}
                makeOrder={this.makeOrder}
                showDialog={showDialog}
                status={status}
                handleCloseNotification={this.handleCloseNotification}
                fairData={fairProducts}
                username={username}
                fairCartProducts={fairCartProducts}
                addProduct={this.addProduct}
                loadingState={this.state.loadingState}
            />
        );
    }
}

CartContainer.defaultProps = {
    data: []
};

const mapStateToProps = state => {
    const cart = state.cart.data;
    const cartId = cart.length > 0 ? cart[cart.length - 1].id : undefined;

    return {
        cartId: cartId,
        data: cart.productList,
        isLoading: state.cart.loading,
        columns: getColumns(),
        username: state.clientDetails.data.username
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindPromiseCreators(
            {
                completeCartPromiseCreator
            },
            dispatch
        ),
        ...bindActionCreators(
            {
                fetchActiveCart
            },
            dispatch
        )
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CartContainer);
