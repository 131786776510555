/**
 * IMPORTANT NOTICE: SVG files need to have attribute data-name that match the same name as is in product.allergens (Entity coming from backend)
 * Based on this is checked if allergens is in product.
 *
 * @returns {*}
 */

const importAllAllergensIcons = () => {
    const r = require.context('!svg-react-loader!../assets/images/allergens/', false, /\.svg$/);
    return r.keys().map(r);
};

export default importAllAllergensIcons;
