/* eslint-disable no-param-reassign */
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import * as React from "react";

const flattenMessages = (nestedMessages, prefix = '') => Object.keys(nestedMessages).reduce((messages, key) => {
        const value       = nestedMessages[key];
        const prefixedKey = prefix ? `${prefix}.${key}` : key;

        if (typeof value === 'string') {
            messages[prefixedKey] = value;
        } else {
            Object.assign(messages, flattenMessages(value, prefixedKey));
        }

        return messages;
    }, {});

const mapStateToProps = (state) => {
    const { lang, messages } = state.locale;

    return { locale: lang, key: lang, messages: flattenMessages(messages), textComponent: React.Fragment };
};

export default connect(mapStateToProps)(IntlProvider);