import React from 'react';
import PropTypes from 'prop-types';
import SelectClient from "./SelectClient";
import {connect} from "react-redux";
import {bindPromiseCreators} from "redux-saga-routines";
import {bindActionCreators} from "redux";
import history from '../../../utils/history';
import {fetchClientDetailsPromiseCreator, setClientUsername} from "../../../routines/user";
import {logoutUser} from "../../../routines/security";
import {fetchFairBasicInfo, fetchFairPoints} from "../../../routines/fair";
import {toast} from "react-toastify";
import {injectIntl} from "react-intl";

class SelectClientContainer extends React.Component {
    state = {
        clientNumber: ''
    };

    componentDidMount() {
        const {place, date, supplierName, security} = this.props;

        if ((!place || !date || !supplierName) || security) {
            this.props.fetchFairBasicInfo();
        }
    }

    sendClientNumber = () => {
        const {clientNumber} = this.state;

        if (clientNumber.length === 0) {
            return;
        }

        const {setClientUsername, fetchClientDetailsPromiseCreator, fetchFairPoints} = this.props;
        setClientUsername(clientNumber);

        fetchClientDetailsPromiseCreator(clientNumber)
            .then(() => {
                    fetchFairPoints({username: clientNumber});
                    history.push('/');
                }, (error) => {
                    toast.error(this.props.intl.formatMessage({id: "select_client.error_client_not_found"}));
                },
            );
        ;
    };

    setClientNumber = (clientNumber) => {
        this.setState({clientNumber})
    };

    render() {
        const {clientNumber, place, date, supplierName, loading, logoutUser} = this.props;

        return <SelectClient
            place={place}
            date={date}
            supplierName={supplierName}
            clientNumber={clientNumber}
            isLoading={loading}
            logout={logoutUser}
            setClientNumber={this.setClientNumber}
            sendClientNumber={this.sendClientNumber}
        />
    }
}

SelectClientContainer.propTypes = {
  clientNumber: PropTypes.any,
  loading: PropTypes.bool,
  loginUser: PropTypes.func,
  logout: PropTypes.func,
  logoutUser: PropTypes.any,
  supplierName: PropTypes.string
};

const mapStateToProps = state => {
    return {
        loading: false,
        place: state.fairInfo.data.place,
        date: state.fairInfo.data.date,
        supplierName: state.fairInfo.data.supplierName,
        security: state.security.data
    }
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindPromiseCreators(
            {
                fetchClientDetailsPromiseCreator
            },
            dispatch
        ),
        ...bindActionCreators(
            {
                fetchFairBasicInfo, fetchFairPoints, setClientUsername, logoutUser
            },
            dispatch
        )
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SelectClientContainer));

