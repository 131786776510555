import React from "react";
import Spinner from '../../../../../Utils/Spinner';

// const FairWeekSubcomponent = ({
//     week,
//     row,
//     fairCartProducts,
//     fairProducts,
//     addProduct,
//     setFairProducts,
//     getInputBorderWidth,
//     getInputBorderColor,
//     getInputColor,
//     setFairCartProducts
// }) => {

class FairWeekSubcomponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inputValue: ''
        }
    }

    render() {
        const {
            week,
            row,
            fairCartProducts,
            fairProducts,
            addProduct,
            setFairProducts,
            getInputBorderWidth,
            getInputBorderColor,
            getInputColor,
            setFairCartProducts,
            loadingState = {},
        } = this.props;
        let activeInputValue = '';

        const loadStateKey = `${row.original.product.id}-${week.week_date}`;

        return (
            <div className="fair-subcomponent-week" key={week.week_date}>
                <div>
                    <span>{week.week_name}</span>
                    {loadingState.hasOwnProperty(loadStateKey) && loadingState[loadStateKey] && (<span style={{ position: 'absolute' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Spinner
                            width={13}
                            height={13}
                        />
                    </span>)}
                    {loadingState.hasOwnProperty(loadStateKey) && loadingState[loadStateKey] ? (
                        <input
                            disabled
                            style={{
                                backgroundColor: getInputColor(week.promo_type),
                                borderColor: getInputBorderColor(week.promo_type),
                                borderWidth: getInputBorderWidth(week.promo_type)
                            }}
                            value={week.value === "0" ? "" : week.value || ""}
                            readOnly={false}
                            type="text"
                            onFocus={e => {
                                this.setState({ inputValue: e.target.value })
                            }}
                            onBlur={e => {
                                if (this.state.inputValue !== e.target.value) {
                                    addProduct(
                                        week.week_date,
                                        row.original.product.id,
                                        e.target.value
                                    )
                                }

                                this.setState({ inputValue: '' });
                            }}
                            onKeyPress={e => {
                                let keynum;

                                if (window.e) {
                                    // IE
                                    keynum = e.keyCode;
                                } else if (e.which) {
                                    // Netscape/Firefox/Opera
                                    keynum = e.which;
                                }

                                if (keynum < 48 || keynum > 57) {
                                    e.preventDefault();
                                }
                            }}
                            onChange={e => {
                                const newValueInWeek = {
                                    [week.week_date]: Number(e.target.value)
                                };
                                const currentProduct = fairProducts.find(
                                    fairProduct =>
                                        fairProduct.product.id ===
                                        row.original.product.id
                                );
                                const newItemInCart = {
                                    [currentProduct.product.id]: {
                                        [week.week_date]: Number(e.target.value)
                                    }
                                };
                                fairProducts.filter(
                                    fairProduct => fairProduct !== currentProduct
                                );
                                if (!fairCartProducts[currentProduct.product.id]) {
                                    Object.assign(fairCartProducts, newItemInCart);
                                } else if (
                                    fairCartProducts[currentProduct.product.id][
                                    week.week_date
                                    ]
                                ) {
                                    fairCartProducts[currentProduct.product.id][
                                        week.week_date
                                    ] = Number(e.target.value);
                                } else if (
                                    !fairCartProducts[currentProduct.product.id][
                                    week.week_date
                                    ]
                                ) {
                                    Object.assign(
                                        fairCartProducts[currentProduct.product.id],
                                        newValueInWeek
                                    );
                                }

                                currentProduct.fair_product_promotions.find(
                                    it => it.week_date === week.week_date
                                ).value = e.target.value;
                                setFairProducts([...fairProducts]);
                                setFairCartProducts(fairCartProducts);
                            }}
                        />
                    ) : (
                        <input
                            style={{
                                backgroundColor: getInputColor(week.promo_type),
                                borderColor: getInputBorderColor(week.promo_type),
                                borderWidth: getInputBorderWidth(week.promo_type)
                            }}
                            value={week.value === "0" ? "" : week.value || ""}
                            readOnly={false}
                            type="text"
                            disabled={week.promo_type === "SS"}
                            onFocus={e => {
                                this.setState({ inputValue: e.target.value })
                            }}
                            onBlur={e => {
                                if (this.state.inputValue !== e.target.value) {
                                    addProduct(
                                        week.week_date,
                                        row.original.product.id,
                                        e.target.value
                                    )
                                }

                                this.setState({ inputValue: '' });
                            }}
                            onKeyPress={e => {
                                let keynum;

                                if (window.e) {
                                    // IE
                                    keynum = e.keyCode;
                                } else if (e.which) {
                                    // Netscape/Firefox/Opera
                                    keynum = e.which;
                                }

                                if (keynum < 48 || keynum > 57) {
                                    e.preventDefault();
                                }
                            }}
                            onChange={e => {
                                const newValueInWeek = {
                                    [week.week_date]: Number(e.target.value)
                                };
                                const currentProduct = fairProducts.find(
                                    fairProduct =>
                                        fairProduct.product.id ===
                                        row.original.product.id
                                );
                                const newItemInCart = {
                                    [currentProduct.product.id]: {
                                        [week.week_date]: Number(e.target.value)
                                    }
                                };
                                fairProducts.filter(
                                    fairProduct => fairProduct !== currentProduct
                                );
                                if (!fairCartProducts[currentProduct.product.id]) {
                                    Object.assign(fairCartProducts, newItemInCart);
                                } else if (
                                    fairCartProducts[currentProduct.product.id][
                                    week.week_date
                                    ]
                                ) {
                                    fairCartProducts[currentProduct.product.id][
                                        week.week_date
                                    ] = Number(e.target.value);
                                } else if (
                                    !fairCartProducts[currentProduct.product.id][
                                    week.week_date
                                    ]
                                ) {
                                    Object.assign(
                                        fairCartProducts[currentProduct.product.id],
                                        newValueInWeek
                                    );
                                }

                                currentProduct.fair_product_promotions.find(
                                    it => it.week_date === week.week_date
                                ).value = e.target.value;
                                setFairProducts([...fairProducts]);
                                setFairCartProducts(fairCartProducts);
                            }}
                        />
                    )
                    }
                </div>
            </div>
        );
    };
}

export default FairWeekSubcomponent;
