import ProductGroups from "./ProductGroups";
import React from "react";
import FairService from "../../../../services/api/Fair";

class ProductGroupsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            groups: []
        }
    }

    componentDidMount() {
        FairService.getFairProductGroups({}).then(response =>
            this.setState({
                groups: response.data
            })
        );
    }

    render() {
        const {selectedGroup, onGroupFilter} = this.props;

        return (
          <ProductGroups selectedGroup={selectedGroup} groups={this.state.groups} onGroupFilter={onGroupFilter}/>
        );
  }
}

export default ProductGroupsContainer;