import request from './request';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) 

const User = {
    getClientDetails: (username) => {
        return request({
            url: isSafari ? `/api/auth/user` : `/api/auth/user/`,
            method: 'GET',
            params: {
                username: username,
                contextGroup: 'fair-app'
            }
        });
    },
    getUserDetails: () => {
        return request({
            url: isSafari ? `/api/auth/user` : `/api/auth/user/`,
            method: 'GET',
            params: {
                contextGroup: 'fair-app'
            }
        });
    },
};

export default User;