import React from "react";
import importAllAllergensIcons from "../../../../services/allergensIconsImporter";

const images = importAllAllergensIcons();

const AllergenList = ({getProperContainInfoClass}) => {
    return (
        <div className="product-details__allergens__list">
            {images.map(Image => (
                <Image key={Image.name} className={`${getProperContainInfoClass(Image.defaultProps['data-name'])} product-details__allergens__list__allergen`}/>
            ))}
        </div>
    );
}

export default AllergenList;
