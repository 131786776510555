import {fetchActiveCart, deleteCart, createCart, addItemCart, completeCart} from '../routines/cart';
import {call, put} from "redux-saga/effects";
import Cart from "../services/api/Cart";
import * as R from 'ramda';

export function* fetchActiveCartSaga(action) {
    try {
        yield put(fetchActiveCart.request());

        const response = yield call(Cart.getActive, action.payload);

        const productList = [];

        response.data.map(cart => {
                cart.basket_items.map(product => {
                    productList.push({...product.product});
                })
            });

        response.data.productList = R.uniq(productList);

        yield put(fetchActiveCart.success({
            data: response.data,
            basketCost: response.headers["x-fair-costs"],
        }));
    } catch (error) {
        yield put(fetchActiveCart.failure(error.message));
    } finally {
        yield put(fetchActiveCart.fulfill());
    }
}

export function* deleteCartSaga(action) {
    try {
        yield put(deleteCart.request());

        const response = yield call(Cart.delete, action.payload);

        yield put(deleteCart.success(response.data));
    } catch (error) {

        yield put(deleteCart.failure(error.message));
    } finally {

        yield put(deleteCart.fulfill());
    }
}

export function* createCartSaga(action) {
    try {
        yield put(createCart.request());

        const response = yield call(Cart.create, action.payload);

        yield put(createCart.success(response.data));
    } catch (error) {

        yield put(createCart.failure(error.message));
    } finally {

        yield put(createCart.fulfill());
    }
}

export function* addProductToCartSaga(action) {
    try {
        yield put(addItemCart.request());

        const response = yield call(Cart.addProduct, action.payload);

        yield put(addItemCart.success({
            data: response.data,
            basketCost: response.headers["x-fair-costs"],
        }));

    } catch (error) {

        yield put(addItemCart.failure(error.message));
    } finally {

        yield put(addItemCart.fulfill());
    }
}

export function* completeCartSaga(action) {
    try {
        yield put(completeCart.request());

        const response = yield call(Cart.complete, action.payload);

        yield put(completeCart.success(response.data));
    } catch (error) {

        yield put(completeCart.failure(error.message));
    } finally {

        yield put(completeCart.fulfill());
    }
}