import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';

const styles = {
    container: {
        width: '400px',
        margin: 'auto',
    },
};

const QRCode = ({onSuccess, onError}) => {
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    return (
        <div style={styles.container}>
            <QrReader
                constraints={{
                    facingMode: 'environment'
                }}
                onResult={(result, error) => {
                    if (!!result) {
                        setData(result?.text);
                        onSuccess(result?.text);
                    }

                    if (!!error) {
                        setError(error?.text);
                        onError(error);
                    }
                }}
                style={{ width: '100%' }}
            />
        </div>
    );
};

export default QRCode;
