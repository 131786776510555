import React from "react";
import {MDBSpinner} from 'mdbreact';

const Spinner = ({width, height, className}) => {
    return (
        <div style={{width: width, height: height, display: 'inline-flex'}}>
            <MDBSpinner className={`custom-spinner ${className}`}/>
        </div>
    );
};

export default Spinner;
