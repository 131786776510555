import React, {Component} from 'react';
import FairWeekSubcomponentContainer from "./components/FairWeekSubcomponent/FairWeekSubcomponentContainer";

class FairSubcomponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {row, fairCartProducts, fairProducts, setFairProducts, setFairCartProducts, addProduct, loadingState} = this.props;

        return (
            <div
                className={`fair-subcomponent ${
                    row.index % 2 === 0 ? "odd" : "even"
                }`}
            >
                <div>
                    {row.original.fair_product_promotions.map(
                        week => {
                            return (
                                <FairWeekSubcomponentContainer
                                    key={week.week_date}
                                    row={row}
                                    week={week}
                                    fairCartProducts={fairCartProducts}
                                    setFairProducts={setFairProducts}
                                    fairProducts={fairProducts}
                                    setFairCartProducts={setFairCartProducts}
                                    addProduct={addProduct}
                                    loadingState={loadingState}
                                />
                            );
                        }
                    )}
                </div>
                {row.original.gadget ? (
                    <div className={"fair-subcomponent-gadget"}>
                        {row.original.gadget}
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    }
}

export default FairSubcomponent;
