import {call, put} from "redux-saga/effects";
import Security from "../services/api/Security";
import history from '../utils/history';
import {loginUser, logoutUser} from "../routines/security";
import store from '../utils/store';
import { resetClientDetails } from '../actions/user';

export function* loginUserSaga(action) {
    try {
        const token = yield call(Security.login, action.payload);
        localStorage.token = token.data.access_token;
        localStorage.refreshToken = token.data.refresh_token;
        yield put(loginUser.success(token.data));

    } catch (error) {
        yield put(loginUser.failure({error: error.message, message: error.response.data.error_description}));
    } finally {
        yield put(loginUser.fulfill());
    }
}

export function* logoutUserSaga() {
    try {
        yield put(logoutUser.request());

        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');

        history.push('/login');

        yield put(logoutUser.success());
        store.dispatch(resetClientDetails());

    } catch (err) {
        yield put(logoutUser.failure(err.message));
    } finally {
        yield put(logoutUser.fulfill());
    }
}
