import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl";
import Spinner from "./Spinner";
import React from "react";
import {Button} from "mdbreact";

const ButtonWithSpinner = ({label, isLoading, width, height, ...props}) => {
    return (
        <Button {...props}>
            {!isLoading ? <FormattedMessage id={label}/> : <Spinner className="spinner--white" width={width} height={height}/>}
        </Button>
    );
};


ButtonWithSpinner.propTypes = {
  height: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  width: PropTypes.string
};

ButtonWithSpinner.defaultProps = {
  height: "0.9rem",
  width: "0.9rem",
  isLoading: false
};

export default ButtonWithSpinner;