import {call, put} from "redux-saga/effects";
import {fetchFairCarts, fetchFairBasicInfo, fetchFairPoints} from "../routines/fair";
import Fair from "../services/api/Fair";
import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/nl';
import store from "../utils/store";

export function* fetchCartsSaga() {
    try {
        yield put(fetchFairCarts.request());

        const response = yield call(Fair.getBaskets);

        yield put(fetchFairCarts.success({data: response.data}));
    } catch (error) {

        yield put(fetchFairCarts.failure(error.message));
    } finally {

        yield put(fetchFairCarts.fulfill());
    }
}

export function* fetchBasicInfoSaga(action) {
    try {
        yield put(fetchFairBasicInfo.request());

        const response = yield call(Fair.getBasicInfo, action.payload);

        const lang = localStorage.lang ? localStorage.lang : store.getState().locale.lang;
        moment.locale(lang);
      
        const startDate = moment(response.data.dateStart.date).format('MMMM Y');
        const endDate = moment(response.data.dateEnd.date).format('MMMM Y');

        response.data.date = startDate;
        if (startDate !== endDate) {
            response.data.date = `${startDate} - ${endDate}`;
        }

        yield put(fetchFairBasicInfo.success(response.data));
    } catch (error) {
        yield put(fetchFairBasicInfo.failure(error.message));
    } finally {
        yield put(fetchFairBasicInfo.fulfill());
    }
}

export function* fetchPointsSaga(action) {
    try {
        yield put(fetchFairPoints.request());

        const response = yield call(Fair.getPointsInfo, action.payload);

        yield put(fetchFairPoints.success(response.data));
    } catch (error) {
        yield put(fetchFairPoints.failure(error.message));
    } finally {
        yield put(fetchFairPoints.fulfill());
    }
}