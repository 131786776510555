import React, { Component } from "react";
import FairWeekSubcomponent from "./FairWeekSubcomponent";
import { connect } from "react-redux";

class FairWeekSubcomponentContainer extends Component {
    getInputColor = type => {
        switch (type) {
            case "X":
                return "white";
            case "S":
                return "#000";
            case "G":
            case "NP":
                return "#0d640073";
            case "B":
                return "pink";
            default:
                return "#ccc";
        }
    };

    getInputBorderColor = type => {
        switch (type) {
            case "G":
            case "NP":
                return "darkgreen";
            default:
                return "black";
        }
    };

    getInputBorderWidth = type => {
        switch (type) {
            case "G":
                return "2px";
            default:
                return "1px";
        }
    };

    render() {
        const {
            week,
            row,
            fairCartProducts,
            fairProducts,
            setFairProducts,
            setFairCartProducts,
            addProduct,
            loadingState,
        } = this.props;

        return (
            <FairWeekSubcomponent
                week={week}
                row={row}
                fairCartProducts={fairCartProducts}
                fairProducts={fairProducts}
                setFairProducts={setFairProducts}
                addProduct={addProduct}
                getInputBorderWidth={this.getInputBorderWidth}
                getInputBorderColor={this.getInputBorderColor}
                getInputColor={this.getInputColor}
                setFairCartProducts={setFairCartProducts}
                loadingState={loadingState}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        username: state.clientDetails.data.username
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FairWeekSubcomponentContainer);
