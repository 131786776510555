import PropTypes from 'prop-types'
import React from 'react';
import {FormattedMessage} from "react-intl";
import {Container, Input} from "mdbreact";
import ButtonWithSpinner from "../../Utils/ButtonWithSpinner";

const Login = ({place, date, login, password, loading, error, handleLogin, handleOnChange}) => (
    <Container fluid className="login">
        <h1 className="login__title">- <FormattedMessage id="login.title"/> -</h1>
        <p>{place && place.toUpperCase()} - {date && date.toUpperCase()}</p>

        <div className="login__box">
            <h2 className="login__box__title"><FormattedMessage id="login.box_title"/></h2>
            <Input
                value={login}
                onChange={handleOnChange}
                data-testid="input-login"
                type="text"
                name="login"
                label={<FormattedMessage id="login.form.login"/>}
            />
            <Input
                value={password}
                onChange={handleOnChange}
                data-testid="input-password"
                type="password"
                name="password"
                label={<FormattedMessage id="login.form.password"/>}
            />
            {error &&
            <p className="inline-message__error">{error.message}</p>
            }
            <ButtonWithSpinner
                data-testid="login-btn"
                className="btn-flat btn-brand"
                onClick={handleLogin}
                label="login.form.btn"
                isLoading={loading}
            />
        </div>
    </Container>
);

Login.propTypes = {
  error: PropTypes.object,
  handleLogin: PropTypes.func,
  handleOnChange: PropTypes.func,
  loading: PropTypes.bool,
  login: PropTypes.string,
  password: PropTypes.string,
  place: PropTypes.string,
  date: PropTypes.string
};

export default Login;