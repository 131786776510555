import React from "react";
import { connect } from "react-redux";
import getColumns from "../../Table/getColumns";
import { fetchProducts } from "../../../routines/product";
import { fetchActiveCart } from "../../../routines/cart";
import * as qs from "qs";
import history from "../../../utils/history";
import FairService from "../../../services/api/Fair";
import SupplierProducts from "./SupplierProducts";

class SupplierProductsContainer extends React.Component {
    constructor(props) {
        super(props);

        const columns = getColumns(['euro']);

        const localStorageColumns = localStorage.getItem(
            'whatColumnsToShowForFairSupplierProducts'
        );

        this.state = {
            columns: columns,
            whatColumnsToShowForFairSupplierProducts: localStorageColumns
                ? JSON.parse(localStorageColumns)
                : columns,
            fairCartProducts: [],
            loadingState: {},
        };
    }

    componentDidMount() {
        FairService.getFairProductsList({ username: this.props.username }).then(
            response => this.setState({ fairCartProducts: response.data })
        );

        const { columns, whatColumnsToShowForFairSupplierProducts } = this.state;
        const updatedColumns = columns.map((column) => {
            const matchedColumn = whatColumnsToShowForFairSupplierProducts.find(
                (c) => c.id === column.id
            );
            if (matchedColumn) {
                return { ...column, show: matchedColumn.show };
            }
            return column;
        });
        this.setState({ columns: updatedColumns });
    }

    fetchData = async params => {
        history.push({
            ...this.props.location,
            search: qs.stringify(params)
        });

        await FairService.getFairProductsList({}).then(
            response => this.setState({ fairCartProducts: response.data })
        );

        params.mylist = 0;

        this.props.fetchProducts(params);
    };

    addProduct = async (week, productId, amount) => {

    }

    handleToggleColumn = (c) => {
        const columns = [];
        columns.push(...this.state.columns);
        columns.forEach((column, index) => {
            if (column.id === c.target.name) {
                columns[index].show = !column.show;
            }
        });
        const columnsObjectsWithBoolean = columns.map((el) => ({
            id: el.id,
            show: el.show,
        }));
        localStorage.setItem(
            'whatColumnsToShowForFairSupplierProducts',
            JSON.stringify(columnsObjectsWithBoolean)
        );
        this.setState({
            columns: columns,
            whatColumnsToShowForFairSupplierProducts: JSON.parse(
                localStorage.getItem('whatColumnsToShowForFairSupplierProducts')
            ),
        });
    };

    render() {
        const { data, itemsCount, isLoading, username } = this.props;
        return (
            <SupplierProducts
                columns={this.state.columns}
                data={data}
                handleToggleColumn={this.handleToggleColumn}
                isLoading={isLoading}
                itemsCount={itemsCount}
                handleFetchData={this.fetchData}
                username={username}
                addProduct={this.addProduct}
                fairCartProducts={this.state.fairCartProducts}
                loadingState={this.state.loadingState}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        username: state.clientDetails.data.username,
        data: state.products.data.data,
        itemsCount: state.products.data.total,
        isLoading: state.products.loading,
    };
};

export default connect(
    mapStateToProps,
    { fetchProducts, fetchActiveCart }
)(SupplierProductsContainer);
