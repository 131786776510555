import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from '../reducers';
import rootSaga from '../rootSaga';
import { composeWithDevTools } from 'redux-devtools-extension';
import {routinePromiseWatcherSaga} from "redux-saga-routines";

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
    reducers,
    composeWithDevTools(
        applyMiddleware(sagaMiddleware)
    )
);

const sagas = [
    rootSaga,
    routinePromiseWatcherSaga
];

sagas.forEach(sagaMiddleware.run);

export default store;