import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import {ToastContainer} from "react-toastify";
import { fetchFairPoints } from "../../routines/fair";

import "font-awesome/css/font-awesome.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "react-table-6/react-table.css"
import 'react-toastify/dist/ReactToastify.css';

import "../../assets/stylesheets/main.css";

import SecurityRoute from "../Routes/SecurityRoute";
import ProtectedRoute from "../Routes/ProtectedRoute";
import Login from "../Security/Login/LoginContainer";
import SelectClient from "../Pages/SelectClient/SelectClientContainer";
import Products from "../Pages/Products/ProductsContainer";
import SupplierProducts from "../Pages/SupplierProducts/SupplierProductsContainer";
import CartContainer from "../Pages/Cart/CartContainer";
import { connect } from "react-redux";
import history from "../../utils/history";
import ProductDetailsContainer from "../ProductDetails/ProductDetailsContainer";
import CacheBuster from "../../cacheBuster";
import packageJson from "../../../package.json";

class App extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { name, loadingUsername } = this.props;
        const {
            location: { pathname }
        } = history;

        if (
            (prevProps.name !== name || !name) &&
            !loadingUsername &&
            pathname !== "/select-client" &&
            pathname !== "/login" &&
            pathname !== "/supplier-products"
        ) {
            history.push("/select-client");
        }
    }

    render() {
        const { location } = history;
        const localStorageVersion = localStorage.getItem("version");

        return (
            <CacheBuster>
                {({ loading, refreshCacheAndReload }) => {
                    if (
                        !loading &&
                        localStorageVersion !== packageJson.version
                    ) {
                        localStorage.setItem("version", packageJson.version);
                        refreshCacheAndReload();
                    }
                    return (
                        <React.Fragment>
                            <Switch>
                                <ProtectedRoute
                                    location={location}
                                    exact
                                    path="/"
                                    component={Products}
                                />
                                <ProtectedRoute
                                    location={location}
                                    exact
                                    path="/supplier-products"
                                    component={SupplierProducts}
                                />
                                <ProtectedRoute
                                    location={location}
                                    exact
                                    path="/cart"
                                    component={CartContainer}
                                />
                                <ProtectedRoute
                                    location={location}
                                    exact
                                    path="/select-client"
                                    component={SelectClient}
                                />
                                <SecurityRoute
                                    location={location}
                                    path="/login"
                                    component={Login}
                                    routeType={Route}
                                />
                            </Switch>
                            {this.props.isAuthenticated && (
                                <React.Fragment>
                                    <ProductDetailsContainer />
                                </React.Fragment>
                            )}
                            <ToastContainer autoClose={4000} />
                        </React.Fragment>
                    );
                }}
            </CacheBuster>
        );
    }
}

App.defaultProps = {
    isAuthenticated: false
};

const mapStateToProps = state => {
    return {
        name: state.clientDetails.data.name,
        username: state.clientDetails.data.username,
        isAuthenticated: !!state.security.data.access_token,
        loadingUsername: state.clientDetails.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchFairPoints
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(App)
);
