import axios from 'axios'
import store from '../../utils/store';
import Security from "./Security";
import history from '../../utils/history';
import {logoutUser} from "../../routines/security";
import {toast} from "react-toastify";

let isFetchingToken = false,
    subscribers = [];

const logoutWithRedirect = () => {
    localStorage.removeItem('token');
    store.dispatch(logoutUser());
    history.push({
        pathname: '/',
    });
};

const sendToastMessage = (error) => {
    let message = '';

    //Setup Error Message
    if (typeof error !== 'undefined' && error.hasOwnProperty('message')) {
        message = error.message
    }

    if (typeof error.response !== 'undefined') {
        //Setup Generic Response Messages
        if (error.response.status === 404) {
            if (history.location.pathname === "/select-client") {
                return;
            }
            message = 'API Route is Missing or Undefined'
        } else if (error.response.status === 405) {
            message = 'API Route Method Not Allowed'
        } else if (error.response.status === 400) {
            message = 'API Validation error'
        }else if (error.response.status >= 500) {
            message = 'Server Error'
        }
        //Try to Use the Response Message
        if (
            error.hasOwnProperty('response')
            && error.response.hasOwnProperty('data')
            && error.response.data.hasOwnProperty('message')
            && error.response.data.message.length > 0
        ) {
            message = error.response.data.message
        }

        //Try to Use the Response Message
        if (
            error.hasOwnProperty('response')
            && error.response.hasOwnProperty('data')
            && error.response.data.hasOwnProperty('error')
            && error.response.data.error === 'invalid_grant'
        ) {
            message = '';
        }
    }

    //Toast the Message
    if(message.length > 0){
        toast.error(message)
    }
};

/**
 * Request Wrapper with default success/error actions
 */
const request = (options) => {
    const lang = localStorage.lang ? localStorage.lang : store.getState().locale.lang;
    const client = axios.create({
        baseURL: `${process.env.REACT_APP_API_URL}/${lang}`
    });

    client.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token;

    const onAccessTokenFetched = (accessToken) => {
        subscribers = subscribers.filter(callback => {
            callback(accessToken)
        });
    };

    client.interceptors.response.use(response => {
        return response
    }, (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401) {
            if (!localStorage.getItem('refreshToken')) {
                logoutWithRedirect();
            }

            if (!isFetchingToken) {
                isFetchingToken = true;
                Security.refresh().then(response => {
                    localStorage.token = response.data.access_token;
                    localStorage.refreshToken = response.data.refresh_token;
                    localStorage.expiresAt = response.data.expires_in; //TODO Add calculation
                    isFetchingToken = false;
                    onAccessTokenFetched(response.data.access_token);
                })
            }

            return new Promise((resolve) => {
                subscribers.push(accessToken => {
                    originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;
                    resolve(client(originalRequest));
                });
            });
        } else {
            sendToastMessage(error)
        }

        return Promise.reject(error)
    });

    return client(options);
};

export default request;
