import PropTypes from 'prop-types'
import React from "react";
import {FormattedMessage} from "react-intl";

const DiscountSummaryBox = ({discount}) => (
    <div className="discount-summary-box">
        <p className="discount-summary-box__title"><FormattedMessage id="discount_summary_box.title"/></p>
        <p className="discount-summary-box__amount-label"><FormattedMessage id="discount_summary_box.amount_label"/>
        </p>
        <p className="discount-summary-box__amount">&euro; {discount}</p>
    </div>
);

DiscountSummaryBox.defaultProps = {
    discount: 31
};

DiscountSummaryBox.propTypes = {
  discount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default DiscountSummaryBox;