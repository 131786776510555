import { fetchProductData } from '../routines/product';
import {call, put} from "redux-saga/effects";
import Product from "../services/api/Product";

export function* fetchProductDetailsSaga(action) {
    try {
        yield put(fetchProductData.request());

        const response = yield call(Product.show, action.payload);

        yield put(fetchProductData.success(response.data));
    } catch (error) {
        yield put(fetchProductData.failure(error.message));
    } finally {
        yield put(fetchProductData.fulfill());
    }
}
