import PropTypes from 'prop-types'
import React from "react";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux"

const ProtectedRoute = ({ component: Component, isAuthenticated,  ...rest }) => {
    if (!isAuthenticated) {
        return <Redirect to="/login"/>
    }

    return <Component {...rest} />
};


ProtectedRoute.propTypes = {
    isAuthenticated: PropTypes.bool
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: !!state.security.data.access_token
    }
};

export default connect(mapStateToProps, null)(ProtectedRoute);