import {fetchActiveCart, createCart, addItemCart, completeCart} from '../routines/cart';

export const cart = (state = {data: [], basketCost: 0, loading: false}, action) => {
    switch (action.type) {
        case fetchActiveCart.SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                basketCost: action.payload.basketCost
            };
        case fetchActiveCart.TRIGGER:
            return {
                ...state,
            };
        case fetchActiveCart.FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case fetchActiveCart.FULFILL:
            return {
                ...state,
            };
        case createCart.SUCCESS:
            if (action.payload.order_type !== 1) {
                return state;
            }

            return {
                ...state,
                data: [...state.data, action.payload]
            };
        case addItemCart.SUCCESS:
            if (action.payload.order_type !== 1) {
                return state;
            }

            return {
                ...state,
                data: state.data.map(
                    c => c.id === action.payload.id ? action.payload : c
                ),
                basketCost: action.payload.basketCost
            };
        case completeCart.SUCCESS:
            return {
                ...state,
                data: [],
                basketCost: 0,
                total: 0,
            };
        default:
            return state;
    }
};