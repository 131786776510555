import {fetchDeliveryDates} from '../routines/delivery';
import Delivery from '../services/api/Delivery';
import {call, put} from "redux-saga/effects";

export function* fetchDeliveryDatesSaga() {
    try {
        yield put(fetchDeliveryDates.request());

        const response = yield call(Delivery.getDates);

        yield put(fetchDeliveryDates.success(response.data));
    } catch (error) {
        yield put(fetchDeliveryDates.failure(error.message));
    } finally {
        yield put(fetchDeliveryDates.fulfill());
    }
}