import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import {Router} from "react-router-dom";
import ReduxConnectedIntlProvider from './utils/reduxConnectedIntlProvider';
import history from './utils/history';
import {Provider} from "react-redux";
import "babel-polyfill";
import 'intl';

import {loginUser} from './routines/security';

import '@formatjs/intl-pluralrules/polyfill';
import nl from '@formatjs/intl-pluralrules/locale-data/nl';
import fr from '@formatjs/intl-pluralrules/locale-data/fr';

import store from "./utils/store";


if (localStorage.token) {
    const token = { access_token: localStorage.token };
    store.dispatch(loginUser.success(token));
}

ReactDOM.render(
    <Provider store={store}>
        <ReduxConnectedIntlProvider>
            <Router history={history}>
                <App/>
            </Router>
        </ReduxConnectedIntlProvider>
    </Provider>
    ,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
