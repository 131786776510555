import PropTypes from 'prop-types'
import React from "react";
import {Col, Row} from "mdbreact";
import {FormattedMessage} from "react-intl";
import FileBox from "./components/FileBox";
import MyOrderListContainer from "../Table/components/MyOrderList/MyOrderListContainer";
import Specification from "./components/Specification";
import Allergens from "./components/Allergens/Allergens";
import Promotions from "./components/Promotions";
import Campaigns from "./components/Campaigns";
import pdfImg from "../../assets/images/pdfImg.svg";
import padArticleNr from "../Utils/padArticleNr";

const ProductDetails = ({
    product, hasTechnicalSheet, isOpen, closeModal, inAssortment, handleDownloadProductSpecificationsPdf, handleRemoveFromAssortment
}) => {
    return (
        <React.Fragment>
            <span onClick={closeModal} className="modal-close"/>
            <section>
                <Row>
                    <Col>
                        <p className="product-details__title">{product.name}</p>
                    </Col>
                </Row>
            </section>
            <section className="product-details__order">
                {inAssortment &&
                <Row>
                    <Col className="product-details__order__on-order-list mb-3">
                        <MyOrderListContainer id={product.id} callback={handleRemoveFromAssortment}/>
                        <FormattedMessage id="product.remove_from_assortiment"/>
                    </Col>
                </Row>
                }
                {/*<Row>*/}
                {/*    <Col size="3">*/}
                {/*        <QuantityContainer*/}
                {/*            productId={product.id}*/}
                {/*            quantity={product.amount ? product.amount : 0} tabIndex={1}*/}
                {/*            itemsInPackage={product.items_in_package}*/}
                {/*            orderAsPackage={product.order_as_package}*/}
                {/*        />*/}
                {/*    </Col>*/}
                {/*    {product.prices && product.prices.length > 0 &&*/}
                {/*    <Col>*/}
                {/*        <span className="product-details__order__price">*/}
                {/*            <ProductPrice price={product.prices[0].price} unit={product.prices[0].unit}/>*/}
                {/*        </span>*/}
                {/*    </Col>*/}
                {/*    }*/}
                {/*</Row>*/}
            </section>
            {(product.promotions || product.campaigns) &&
            <section className="product-details__promotion-campaign">
                <Row>
                    <Col>
                        {product.promotions &&
                        <Promotions promotions={product.promotions}/>
                        }
                        {product.campaigns &&
                        <Campaigns campaigns={product.campaigns}/>
                        }
                    </Col>
                </Row>
            </section>
            }
            <section className="product-details__images">
                <Row>
                    <Col>
                        <div className="product-details__images__wrapper">
                            {product.images.map(image =>
                                <img key={image.url} src={image.url} className="product-details__images__wrapper__image img-fluid" alt="product"/>
                            )}
                        </div>
                    </Col>
                </Row>
            </section>
            <section className="product-details__specifications">
                <Row>
                    <Col>
                        <p className="product-details__section__title">
                            <FormattedMessage id="product_details.specifications.title"/>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col size="6">
                        {product.article_nr && <Specification label="product_details.specifications.article_nr" value={padArticleNr(product.article_nr)}/>}
                        {product.weight && <Specification label="product_details.specifications.weight" value={product.weight}/>}
                    </Col>
                    <Col size="6">
                        {product.availableFrom && <Specification label="product_details.specifications.availability" value={product.availableFrom}/>}
                        {product.supplier && <Specification label="product_details.specifications.supplier" value={product.supplier}/>}
                        {product.ean14 && <Specification label="product_details.specifications.ean_14" value={product.ean14}/>}
                        {product.ean13 && <Specification label="product_details.specifications.ean_13" value={product.ean13}/>}
                    </Col>
                </Row>
            </section>
            <section className="product-details__description">
                <Row>
                    <Col>
                        <p className="product-details__section__title">
                            <FormattedMessage id="product_details.description.title"/>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {product.description ?
                            product.description : <FormattedMessage id="product_details.description.no_description"/>
                        }
                    </Col>
                </Row>
            </section>
            <section className="product-details__allergens">
                <Row>
                    <Col>
                        <p className="product-details__section__title">
                            <FormattedMessage id="product_details.allergens.title"/>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Allergens allergens={product.allergens}/>
                    </Col>
                </Row>
            </section>
            {(product.allergens.has_sheet || product.files) &&
            <section className="product-details__downloads">
                <Row>
                    <Col>
                        <p className="product-details__section__title">
                            <FormattedMessage id="product_details.downloads.title"/>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex flex-wrap">
                        {product.allergens.has_sheet &&
                            <FileBox
                                key={0}
                                name={<FormattedMessage id="product_details.downloads.sheet_file_title"/>}
                                handleDownload={handleDownloadProductSpecificationsPdf}
                                image={{
                                    path: pdfImg,
                                    width: 55,
                                    height: 69
                                }}
                            />
                        }
                        {product.files && product.files.map(file => (
                            <FileBox key={file.name} name={file.name} link={file.downloadUrl} image={file.image}/>
                        ))}
                    </Col>
                </Row>
            </section>
            }
        </React.Fragment>
    );
};

ProductDetails.propTypes = {
  closeModal: PropTypes.func,
  files: PropTypes.array,
  handleDownloadAllergensSheet: PropTypes.func,
  hasTechnicalSheet: PropTypes.bool,
  isOpen: PropTypes.bool,
  onOrderList: PropTypes.bool,
  product: PropTypes.object
};

export default ProductDetails;