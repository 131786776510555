import PropTypes from "prop-types";
import React from "react";
import Header from "../../Header/HeaderContainer";
import { Container } from "mdbreact";
import PageHeader from "../../PageHeader/PageHeader";
import Table from "../../Table/Table";
import { FormattedMessage } from "react-intl";
import ButtonWithSpinner from "../../Utils/ButtonWithSpinner";
import NotificationContainer from "../../Notification/NotificationContainer";

const Cart = ({
    data,
    columns,
    isLoading,
    makeOrder,
    status,
    showDialog,
    handleCloseNotification,
    fairData,
    fairCartProducts,
    username,
    addProduct,
    loadingState
}) => {
    const fairProducts = [];
    fairData.map(it =>
        fairCartProducts[it.product.id] ? fairProducts.push(it) : null
    );

    return (
        <div className="cart">
            <Header />
            <Container className="px-0">
                <PageHeader withSearchAndDate={false} cartItems={fairCartProducts}/>
                <h1 className="cart__title" data-testid="cart-title">
                    <FormattedMessage id="cart.title" />
                </h1>
                <Table
                    data={fairProducts}
                    columns={columns}
                    itemsCount={data.length}
                    pageSize={fairProducts.length}
                    showPaginationBottom={false}
                    showPageSizeOptions={false}
                    loading={isLoading}
                    username={username}
                    addProduct={addProduct}
                    loadingState={loadingState}
                />

                {/* <div className="d-flex justify-content-center">
                    <ButtonWithSpinner
                        disabled={data.length === 0}
                        onClick={makeOrder}
                        label="cart.make_order"
                        className="cart__make-order btn-flat btn-success"
                    />
                </div> */}

                <NotificationContainer
                    status={status}
                    type="dialog"
                    title={
                        status === "error" ? (
                            <FormattedMessage id="error" />
                        ) : (
                            <FormattedMessage id="cart.order.placed_success" />
                        )
                    }
                    text={
                        status === "error" && (
                            <FormattedMessage id="error_call" />
                        )
                    }
                    show={showDialog}
                    handleClose={handleCloseNotification}
                />
            </Container>
        </div>
    );
};

Cart.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    handleFetchData: PropTypes.func,
    isLoading: PropTypes.bool,
    makeOrder: PropTypes.func
};

export default Cart;