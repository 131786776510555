import {call, put} from "redux-saga/effects";
import {fetchClientDetails, fetchUserDetails, setClientUsername} from "../routines/user";
import User from "../services/api/User";

export function* fetchUserDetailsSaga(action) {
    try {
        yield put(fetchUserDetails.request());

        const response = yield call(User.getUserDetails, action.payload);

        yield put(fetchUserDetails.success(response.data));
    } catch (error) {
        yield put(fetchUserDetails.failure(error.message));
    } finally {
        yield put(fetchUserDetails.fulfill());
    }
}

export function* fetchClientDetailsSaga(action) {
    try {
        yield put(fetchClientDetails.request());

        const response = yield call(User.getClientDetails, action.payload);

        yield put(fetchClientDetails.success(response.data));
    } catch (error) {
        yield put(fetchClientDetails.failure(error.message));
    } finally {
        yield put(fetchClientDetails.fulfill());
    }
}

export function* setClientUsernameSaga(action) {
    try {
        yield put(setClientUsername.request());

        yield put(setClientUsername.success(action.payload));
    } catch (error) {
        yield put(setClientUsername.failure(error.message));
    } finally {
        yield put(setClientUsername.fulfill());
    }
}