import PropTypes from 'prop-types'
import React from "react";
import moment from "moment";

const Promotions = ({promotions}) => (
    <React.Fragment>
        {promotions.map(promotion => {
            const deliveryDate = new Date(sessionStorage.getItem('deliveryDate')) ? moment(new Date(sessionStorage.getItem('deliveryDate'))).format() : null;
            if (moment(deliveryDate).isBetween(promotion.start_date, promotion.end_date, null, '[]')) {
                return (
                    <div
                        className={`product-details__promotion product-details__promotion--${promotion.promotion_type}`}
                        dangerouslySetInnerHTML={{ __html: promotion.promotion}} />
                )
            }

        })}
    </React.Fragment>
);

export default Promotions;

Promotions.propTypes = {
  promotions: PropTypes.array
}