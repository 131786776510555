import { combineReducers } from "redux";
import { locale } from "./locale";
import {loginUser, security} from "./security";
import {deliveryDates} from "./delivery";
import {products} from "./product";
import {cart} from "./cart";
import {clientDetails} from "./user";
import {fairInfo, fairPoints} from "./fair";
import {productDetails, productDetailsModal} from './productDetails';

export default combineReducers({
    locale,
    security,
    loginUser,
    deliveryDates,
    products,
    cart,
    clientDetails,
    fairInfo,
    fairPoints,
    productDetails,
    productDetailsModal
})