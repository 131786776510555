import React from "react";
import Products from "./Products";
import { connect } from "react-redux";
import getColumns from "../../Table/getColumns";
import { fetchProducts } from "../../../routines/product";
import { fetchActiveCart } from "../../../routines/cart";
import * as qs from "qs";
import history from "../../../utils/history";
import FairService from "../../../services/api/Fair";

class ProductsContainer extends React.Component {
    constructor(props) {
        super(props);

        const columns = getColumns();

        const localStorageColumns = localStorage.getItem(
            'whatColumnsToShowForFairProducts'
        );

        this.state = {
            columns: columns,
            whatColumnsToShowForFairProducts: localStorageColumns
                ? JSON.parse(localStorageColumns)
                : columns,
            fairCartProducts: [],
            loadingState: {},
        };
    }
    
    componentDidMount() {
        FairService.getFairProductsList({ username: this.props.username }).then(
            response => this.setState({ fairCartProducts: response.data })
        );

        const { columns, whatColumnsToShowForFairProducts } = this.state;
        const updatedColumns = columns.map((column) => {
            const matchedColumn = whatColumnsToShowForFairProducts.find(
                (c) => c.id === column.id
            );
            if (matchedColumn) {
                return { ...column, show: matchedColumn.show };
            }
            return column;
        });
        this.setState({ columns: updatedColumns });
    }

    fetchData = async params => {
        params = { ...params, username: this.props.username };
        history.push({
            ...this.props.location,
            search: qs.stringify(params)
        });

        await FairService.getFairProductsList({ username: this.props.username }).then(
            response => this.setState({ fairCartProducts: response.data })
        );

        this.props.fetchProducts(params);
    };

    addProduct = async (week, productId, amount) => {
        const loadStateKey = `${productId}-${week}`;

        this.setState((prevState) => ({ loadingState: {...prevState.loadingState, [loadStateKey]: true} }))

        await FairService.addProduct(
            week,
            productId,
            amount === "" ? 0 : amount,
            { username: this.props.username }
        );

        //const queryParams = qs.parse(history.location.search.slice(1));
        // this.fetchData(queryParams);

        this.props.fetchActiveCart();

        await FairService.getFairProductsList({ username: this.props.username }).then(
            response => this.setState((prevState) => ({ fairCartProducts: response.data, loadingState: {...prevState.loadingState, [loadStateKey]: false} }))
        );
    };

    handleToggleColumn = (c) => {
        const columns = [];
        columns.push(...this.state.columns);
        columns.forEach((column, index) => {
            if (column.id === c.target.name) {
                columns[index].show = !column.show;
            }
        });
        const columnsObjectsWithBoolean = columns.map((el) => ({
            id: el.id,
            show: el.show,
        }));
        localStorage.setItem(
            'whatColumnsToShowForFairProducts',
            JSON.stringify(columnsObjectsWithBoolean)
        );
        this.setState({
            columns: columns,
            whatColumnsToShowForFairProducts: JSON.parse(
                localStorage.getItem('whatColumnsToShowForFairProducts')
            ),
        });
    };

    render() {
        const { data, itemsCount, isLoading, username } = this.props;
        return (
            <Products
                columns={this.state.columns}
                data={data}
                handleToggleColumn={this.handleToggleColumn}
                isLoading={isLoading}
                itemsCount={itemsCount}
                handleFetchData={this.fetchData}
                username={username}
                addProduct={this.addProduct}
                fairCartProducts={this.state.fairCartProducts}
                loadingState={this.state.loadingState}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        username: state.clientDetails.data.username,
        data: state.products.data.data,
        itemsCount: state.products.data.total,
        isLoading: state.products.loading,
    };
};

export default connect(
    mapStateToProps,
    { fetchProducts, fetchActiveCart }
)(ProductsContainer);
