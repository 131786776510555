import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Col, Container, Row } from "mdbreact";
import clientList from "../../assets/images/client-list.svg";
import Spinner from "../Utils/Spinner";
import pdf from "../../assets/actie_bourgon.pdf";

const HeaderSupplier = ({
    handleClientListClick,
    toggleLocale,
    lang
}) => {
    return (
        <Container fluid className="header">
            <Row>
                <Col size="9">

                </Col>
                <Col size="2">
                    <span
                        onClick={handleClientListClick}
                        className="header__client-list"
                        data-testid="client-list"
                    >
                        <FormattedMessage id="header.retour" />
                        <img src={clientList} alt="retour" />
                    </span>
                </Col>
                <Col size="1">
                    <span
                        onClick={() => toggleLocale()}
                        className="header__language"
                    >
                        {lang === "nl" ? "FR" : "NL"}
                    </span>
                </Col>
            </Row>
        </Container>
    );
};

HeaderSupplier.propTypes = {
    clientName: PropTypes.string,
    clientPoints: PropTypes.number,
    maxPoints: PropTypes.number
};

export default HeaderSupplier;
