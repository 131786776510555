import PropTypes from 'prop-types'
import React from "react";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";

const SecurityRoute = ({ component: Component, routeType: Route, isAuthenticated,  ...rest }) => (
    <Route {...rest} render={(props) => (
        isAuthenticated === false ?
            <Component {...props} /> : localStorage.getItem('basketId') ? <Redirect exact to='/'/> : <Redirect exact to='/select-client'/>
    )} />
);


const mapStateToProps = (state) => {
    return {
        isAuthenticated: !!state.security.data.access_token
    }
};

SecurityRoute.propTypes = {
  isAuthenticated: PropTypes.bool
};

export default connect(mapStateToProps, null)(SecurityRoute);