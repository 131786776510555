import {fetchProducts, toggleProductFavorite} from '../routines/product';
import {call, put} from "redux-saga/effects";
import Product from "../services/api/Product";

export function* toggleProductFavouriteSaga(action) {
    try {
        yield put(toggleProductFavorite.request());

        const response = yield call(Product.toggleFavourite, action.payload);

        yield put(toggleProductFavorite.success(response.data));
    } catch (error) {
        yield put(toggleProductFavorite.failure(error.message));
    } finally {
        yield put(toggleProductFavorite.fulfill());
    }
}

export function* fetchProductsSaga(action) {
    try {
        yield put(fetchProducts.request());

        const response = yield call(Product.get, action.payload);

        yield put(fetchProducts.success({
            data: response.data,
            total: response.headers["x-total-count"],
            countAll: response.headers["x-all-count"],
            countUser: response.headers["x-user-count"]
        }));
    } catch (error) {
        yield put(fetchProducts.failure(error.message));
    } finally {
        yield put(fetchProducts.fulfill());
    }
}