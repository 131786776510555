import request from './request';
import store from '../../utils/store';

const Cart = {
    create: (date) => {
        return request({
            url: `/api/basket/create/1`,
            method: 'POST',
            data: {
                deliveryDate: date,
                username: store.getState().clientDetails.data.username
            }
        });
    },
    delete: () => {
        return request({
            url: `/api/basket`,
            method: 'DELETE',
            params: {
                username: store.getState().clientDetails.data.username
            }
        });
    },
    getActive: () => {
        return request({
            url: `/api/basket/active`,
            method: 'GET',
            params: {
                username: store.getState().clientDetails.data.username
            }
        });
    },
    addProduct: ({productId, cartId, amount}) => {
        return request({
            url: `/api/basket/${cartId}/${productId}/${amount}`,
            method: 'PUT',
            params: {
                username: store.getState().clientDetails.data.username
            }
        });
    },
    complete: () => {
        return request({
            url: `/api/fair-cart/complete`,
            method: 'PATCH',
            params: {
                username: store.getState().clientDetails.data.username
            }
        });
    },
};

export default Cart;