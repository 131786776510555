import React from "react";

const Campaigns = ({campaigns}) => (
    <React.Fragment>
        {campaigns.map(campaign => (
            <div className="product-details__campaign" style={{backgroundColor: campaign.campaign.color}}>
                {campaign.campaign.name}: {campaign.points} pt.
            </div>
        ))}
    </React.Fragment>
);

export default Campaigns;
