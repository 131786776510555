import PropTypes from "prop-types";
import React from "react";
import Header from "../../Header/HeaderContainer";
import { Container } from "mdbreact";
import PageHeader from "../../PageHeader/PageHeader";
import Table from "../../Table/Table";

const Products = ({
    data,
    columns, handleToggleColumn,
    itemsCount,
    handleFetchData,
    isLoading,
    username,
    addProduct,
    fairCartProducts,
    loadingState,
}) => 
{
    return (
    <div className="products">
        <Header />
        <Container className="px-0">
            <PageHeader cartItems={fairCartProducts} />
            <Table
                data={data}
                columns={columns}
                handleToggleColumn={handleToggleColumn}
                loading={isLoading}
                itemsCount={itemsCount}
                manual
                onFetchData={state => handleFetchData(state)}
                username={username}
                addProduct={addProduct}
                loadingState={loadingState}
            />
        </Container>
    </div>
);
}

Products.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    isLoading: PropTypes.bool
};

export default Products;
