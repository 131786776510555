import PropTypes from "prop-types";
import React from "react";
import Header from "../../Header/HeaderContainer";
import { Container } from "mdbreact";
import PageHeader from "../../PageHeader/PageHeader";
import Table from "../../Table/Table";

const SupplierProducts = ({
                      data,
                      columns,
                              handleToggleColumn,
                      itemsCount,
                      handleFetchData,
                      isLoading,
                      username,
                      addProduct,
                      fairCartProducts,
                      loadingState,
                  }) =>
{
    return (
        <div className="products">
            <Header isSupplier={true} />
            <Container className="px-0">
                <PageHeader cartItems={fairCartProducts} isSupplier={true} />
                <Table
                    data={data}
                    columns={columns}
                    handleToggleColumn={handleToggleColumn}
                    loading={isLoading}
                    itemsCount={itemsCount}
                    manual
                    onFetchData={state => handleFetchData(state)}
                    username={username}
                    addProduct={addProduct}
                    loadingState={loadingState}
                />
            </Container>
        </div>
    );
}

SupplierProducts.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    isLoading: PropTypes.bool
};

export default SupplierProducts;
