import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Input} from "mdbreact";
import ButtonWithSpinner from "../../Utils/ButtonWithSpinner";

const ClientForm = ({clientNumber, isLoading, setClientNumber, sendClientNumber}) => {

    const onChange = (event) => {
        setClientNumber(event.target.value);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        sendClientNumber();
    };

    return (
        <>
            <h2 className="select-client__box__title"><FormattedMessage id="select_client.box_title"/></h2>
            <form className="select-client__box__form" onSubmit={onSubmit} method="post">
                <Input
                    value={clientNumber}
                    onChange={onChange}
                    data-testid="input-client-nr"
                    type="text"
                    name="clientNumber"
                    label={<FormattedMessage id="select_client.form.client_number"/>}
                />
                <ButtonWithSpinner
                    isLoading={isLoading}
                    data-testid="btn"
                    label="select_client.form.btn"
                    className="btn-brand btn-flat"
                    type="submit"
                />
            </form>
        </>
    );
};

ClientForm.propTypes = {
    clientNumber: PropTypes.string,
    isLoading: PropTypes.any,
    setClientNumber: PropTypes.func,
    sendClientNumber: PropTypes.func
};

export default ClientForm;
