import request from './request';
import store from '../../utils/store';

const Delivery = {
    getDates: () => {
        return request({
            url: '/api/fair/delivery-dates',
            method: 'GET',
            params: {
                username: store.getState().clientDetails.data.username
            }
        });
    },
};

export default Delivery;