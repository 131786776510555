import {fetchProducts} from "../routines/product";

const initialState = {
    data: [],
    loading: false,
    error: null,
};

export const products = (state = initialState, action) => {
    switch (action.type) {
        case fetchProducts.TRIGGER:
            return {
                ...state,
            };
        case fetchProducts.SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case fetchProducts.FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case fetchProducts.FULFILL:
            return {
                ...state,
            };
        default:
            return state;
    }
};