import React from "react";
import * as qs from "qs";
import history from '../../utils/history';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {fetchProducts} from "../../routines/product";

class Search extends React.Component {

    state = {
        value: ''
    };

    componentDidMount() {
        const queryParams = qs.parse(history.location.search.slice(1));
        if (queryParams['s_all']) {
            this.setState({
                value: queryParams['s_all']
            })
        }
    }

    onChange = (e) => {
        this.setState({
            value: e.target.value
        })
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const searchTerm = this.state.value;

        const queryParams = qs.parse(history.location.search.slice(1));
        if (searchTerm) {
            queryParams['s_all'] = searchTerm;
        } else {
            delete queryParams['s_all'];
        }

        history.push({
            ...this.props.location,
            search: qs.stringify(queryParams)
        });

        this.props.fetchProducts(queryParams);
    };

    render() {
        const {intl} = this.props;

        return (
            <form data-testid="search-form" method="get" className="search" onSubmit={this.handleSubmit}>
                <input
                    value={this.state.value}
                    onChange={this.onChange}
                    placeholder={intl.formatMessage({id: "search.placeholder"})}
                    type="text"
                    className="search__input"
                    data-testid="search-input"
                />
                <i onClick={this.handleSubmit} className="search__icon" data-testid="search-icon"/>
            </form>
        )
    }
}

export default injectIntl(connect(null, {fetchProducts})(Search));
