import PropTypes from "prop-types";
import React from "react";
import FilterBox from "./components/FilterBox/FilterBox";
import { FormattedMessage } from "react-intl";

const Filters = ({
    allItemsCount,
    clientItemsCount,
    cartItems,
    onSelectFilter,
    isProductCountLoading,
    myList,
    pathname,
    isSupplier
}) => (
    <React.Fragment>
        {isSupplier && (
            <FilterBox
                key="all"
                name={<FormattedMessage id="filters.all_items" />}
                itemsCount={allItemsCount}
                onSelectFilter={() => onSelectFilter("all")}
                isProductCountLoading={isProductCountLoading}
                myList={myList === 0 && myList}
                isSupplier={isSupplier}
            />
        )}
        {!isSupplier && (
            <React.Fragment>
                <FilterBox
                    key="all"
                    name={<FormattedMessage id="filters.all_items" />}
                    itemsCount={allItemsCount}
                    onSelectFilter={() => onSelectFilter("all")}
                    isProductCountLoading={isProductCountLoading}
                    myList={myList === 0 && myList}
                />
                <FilterBox
                    key="client"
                    name={<FormattedMessage id="filters.client_items" />}
                    itemsCount={clientItemsCount}
                    onSelectFilter={() => onSelectFilter("client")}
                    isProductCountLoading={isProductCountLoading}
                    myList={myList === 1 && pathname !== "/cart" && myList}
                />
                <FilterBox
                    key="cart"
                    name={<FormattedMessage id="filters.cart_items" />}
                    itemsCount={Object.keys(cartItems).length}
                    onSelectFilter={() => onSelectFilter("cart")}
                    isProductCountLoading={isProductCountLoading}
                    myList={pathname === "/cart" && 2}
                />
            </React.Fragment>
        )}
    </React.Fragment>
);

Filters.propTypes = {
    allItemsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    clientItemsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isProductCountLoading: PropTypes.bool,
    onSelectFilter: PropTypes.func.isRequired
};

Filters.defaultProps = {
    allItemsCount: 0,
    clientItemsCount: 0,
    isProductCountLoading: false
};

export default Filters;
