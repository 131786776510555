import routineReducerFactory from "../utils/routineReducerFactory";
import {loginUser as loginUserRoutine, logoutUser} from "../routines/security";

export const loginUser = routineReducerFactory(loginUserRoutine, {data: [], loading: false});

export const security = (state = {data: {}, loading: false}, action) => {
    switch (action.type) {
        case loginUserRoutine.TRIGGER:
            return {
                ...state,
                loading: true,
            };
        case loginUserRoutine.SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case logoutUser.SUCCESS:
            return {
                ...state,
                data: {}
            };
        case loginUserRoutine.FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case loginUserRoutine.FULFILL:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};