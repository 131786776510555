import PropTypes from 'prop-types'
import React from "react";
import Filters from "../Filters/FiltersContainer";
import DiscountSummaryBox from "../DiscountSummaryBox/DiscountSummaryBoxContainer";
import Search from "../Search/Search";

const PageHeader = ({ withSearchAndDate, cartItems, isSupplier }) => {
  return (
    <div className="page-header">
      <div className="page-header__boxes">
        <Filters cartItems={cartItems} isSupplier={isSupplier} />
          {!isSupplier && (
            <DiscountSummaryBox />
          )}
      </div>
      {withSearchAndDate &&
        <div className="page-header__search">
          <Search />
        </div>
      }
    </div>
  );
}


PageHeader.propTypes = {
  withSearchAndDate: PropTypes.bool
};

PageHeader.defaultProps = {
  withSearchAndDate: true
};

export default PageHeader;