const ProductPhoto = ({ product, className = '' }) => {
    const imgSrc =
        product.images && product.images.length ? product.images[0].url : '';

    if (!imgSrc) {
        return '';
    }

    return <img className={className} src={imgSrc} alt={product.name} />;
};

export default ProductPhoto;
