import {fetchClientDetails, setClientUsername} from "../routines/user";
import {RESET_CLIENT_DETAILS} from "../actions/user";

const defaultState = {data: [], loading: false};

export const clientDetails = (state = defaultState, action) => {
    const initialState = {
        data: defaultState.data !== undefined ? defaultState.data : null,
        loading: defaultState.loading !== undefined ? defaultState.loading : true,
        error: defaultState.error !== undefined ? defaultState.error : null,
    };

    switch (action.type) {
        case fetchClientDetails.TRIGGER:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fetchClientDetails.SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case fetchClientDetails.FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case fetchClientDetails.FULFILL:
            return {
                ...state,
                loading: false,
            };
        case RESET_CLIENT_DETAILS:
            return {
                ...state,
                data: initialState.data
            };
        case setClientUsername.SUCCESS:
            return {
                ...state,
                data: {
                    username: action.payload
                }
            };
        default:
            return state;
    }

};